import { createTheme } from '@mui/material';

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#0c2d48',
    },
    secondary: {
      main: '#8A8E91',
    },
  },
  typography: {
    boldH3Title: {
      fontWeight: 600,
    },
    body1: {
      fontWeight: 500,
    },
    button: {
      textTransform: 'none',
      fontSize: '16px',
    },
    fonts: {
      AtkinsonHyperlegible: 'Atkinson Hyperlegible',
      Roboto: 'Roboto',
      UbuntuMono: 'Ubuntu Mono',
    },
    fontFamily: ['Atkinson Hyperlegible', 'Roboto', 'Ubuntu Mono'].join(','),
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'correct', color: 'primary' },
          style: {
            color: 'white',
            backgroundColor: '#13c373',
            opacity: 0.8,
            '&:hover': {
              backgroundColor: '#13c373',
              opacity: 1,
            },
            border: '1px solid',
          },
        },
        {
          props: { variant: 'wrong', color: 'secondary' },
          style: {
            backgroundColor: '#555',
          },
        },
        {
          props: { shape: 'rounded' },
          style: {
            borderRadius: 20,
          },
        },
      ],
    },
    MyThemeComponent: {
      styleOverrides: {
        root: {
          color: 'darkslategray',
        },
        primary: {
          color: 'darkblue',
        },
        secondary: {
          color: 'darkred',
          backgroundColor: 'pink',
        },
      },
      variants: [
        {
          props: { variant: 'dashed', color: 'primary' },
          style: {
            border: '1px dashed darkblue',
          },
        },
        {
          props: { variant: 'dashed', color: 'secondary' },
          style: {
            border: '1px dashed darkred',
          },
        },
      ],
    },
  },
});

export default customTheme;
