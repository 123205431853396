import React, { useContext } from 'react';
import { LessonContext } from 'Contexts/LessonContext';
import { useNavigate } from 'react-router-dom';
import { Grid, Box } from '@mui/material';
import Lock from 'assets/img/Lock.png';
import Done from 'assets/img/Done.png';
import Bulb from 'assets/img/Bulb.png';

import { PropTypes } from 'prop-types';

import theme from 'theme';
import styled from 'styled-components';

export const LessonsGridItem = ({
  lesson,
  completed,
  handleOpen,
  availableLesson,
}) => {
  const { setLessonIdContext } = useContext(LessonContext);
  const navigate = useNavigate();

  return (
    <Grid
      key={lesson.lessonNumber}
      item
      style={{
        padding: '11px',
      }}
    >
      <LessonCard
        $availableLesson={availableLesson}
        onClick={() => {
          if (availableLesson) {
            setLessonIdContext(lesson.lessonNumber);
            navigate(`/lessons/${lesson.lessonNumber}`);
          } else {
            handleOpen();
          }
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <LessonNumber>{lesson.lessonNumber}</LessonNumber>
          {!completed && !lesson.tutorial && !availableLesson && <LockImage />}
          {completed && !lesson.tutorial && availableLesson && <DoneImage />}
          {lesson.tutorial && <BulbImage />}
        </div>
        <LessonCardContent>
          <LessonIcon url={lesson.iconUrl} />
        </LessonCardContent>
        <LessonTitleWrapper>
          <LessonTitle theme={theme}>{lesson.name}</LessonTitle>
        </LessonTitleWrapper>
      </LessonCard>
    </Grid>
  );
};

LessonsGridItem.propTypes = {
  lesson: PropTypes.object.isRequired,
  completed: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
  availableLesson: PropTypes.bool.isRequired,
};

const LessonCard = styled(Box)`
  display: flex;
  flex-direction: column;
  min-width: 160px;
  min-height: 160px;
  max-width: 160px;
  max-height: 160px;
  background: white;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 80%);
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 3px;
  cursor: pointer;
  opacity: ${(props) => (props.$availableLesson ? 1 : 0.7)};
`;

const LessonNumber = styled.div`
  font-size: 30px;
  font-weight: 700;
  font-family: Atkinson Hyperlegible;
  padding: 2px 0px 0px 5px;
`;

const LessonCardContent = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 50px;
  width: 65px;
  height: 65px;
  margin-top: 5px;
`;

const LessonIcon = styled.div`
  background-image: ${(props) => `url(${props.url})`};
  min-width: 140px;
  min-height: 110px;
  background-repeat: no-repeat;
`;

const LessonTitleWrapper = styled(Box)`
  border-top: solid 1px red;
  margin-top: 25px;
  width: 160px;
`;

const LessonTitle = styled.div`
  text-align: center;
  padding: 5px 10px 0px 10px;
  font-size: 15px;
  font-weight: 200;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LockImage = styled(Box)({
  backgroundImage: `url(${Lock})`,
  backgroundRepeat: `no-repeat`,
  width: `30px`,
  height: `40px`,
  marginRight: '10px',
});

const DoneImage = styled(Box)({
  backgroundImage: `url(${Done})`,
  backgroundRepeat: `no-repeat`,
  width: `33px`,
  height: `40px`,
  marginRight: '10px',
});

const BulbImage = styled(Box)({
  backgroundImage: `url(${Bulb})`,
  backgroundRepeat: `no-repeat`,
  width: `33px`,
  height: `40px`,
  marginRight: '10px',
});
