import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';

export const AuthNavbar = () => {
  const navigate = useNavigate();
  return (
    <StyledAppBar>
      <Toolbar>
        <LogoStyling onClick={() => navigate('/')}>FretBuddy</LogoStyling>
      </Toolbar>
    </StyledAppBar>
  );
};

const StyledAppBar = styled(AppBar)`
  background: transparent;
  box-shadow: none;
  position: relative;
  margin-bottom: 50px;
`;

const LogoStyling = styled(Typography)`
  flex-grow: 1;
  font-size: 25px;
  font-weight: 700;
  color: #0c2d48;
  cursor: pointer;
`;
