/* eslint-disable global-require */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-dynamic-require */
import React, { useState, useContext, useRef } from 'react';
// eslint-disable-next-line import/no-unresolved
import { GuitarContext } from 'Contexts/GuitarContext';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useSound from 'use-sound';
import Typography from '@mui/material/Typography';

export function String({
  stringIndex,
  fretIndex,
  note,
  selectedNote,
  hideNote,
  accidentals,
  naturals,
  hideHover,
}) {
  const { setNoteClicked, soundOff } = useContext(GuitarContext);
  const noteSound = require(`../../sounds/guitar/${note}.mp3`);
  const containerRef = useRef(null);

  // Removes the octave value
  let trueNote = note.slice(0, -1);

  // If it is an accidental note, it will be reformated to example: F#/Gb
  if (trueNote.includes('s')) {
    trueNote = trueNote.split('s');
    trueNote = `${trueNote[0]}#/${trueNote[1]}`;
  }

  const [noteHovered, setNoteHovered] = useState(false);
  const [play] = useSound(noteSound);

  const playNote = () => {
    setNoteClicked((prevState) => ({
      ...prevState,
      fretIndex,
      stringIndex,
      note: trueNote,
    }));
    if (!soundOff) {
      play();
    }
  };

  function checkNaturalAccidental() {
    if (accidentals && naturals) {
      return (
        <Note onClick={play}>
          <NoteLetters>{trueNote}</NoteLetters>
        </Note>
      );
    }
    if (accidentals) {
      if (trueNote.includes('b')) {
        return (
          <Note onClick={play}>
            <NoteLetters>{trueNote}</NoteLetters>
          </Note>
        );
      }
      return null;
    }
    if (naturals) {
      if (!trueNote.includes('b')) {
        return (
          <Note onClick={play}>
            <NoteLetters>{trueNote}</NoteLetters>
          </Note>
        );
      }
      return null;
    }
    return null;
  }

  if (hideNote) {
    return (
      <StringStyle
        onMouseEnter={() => {
          setNoteHovered(true);
        }}
        onMouseLeave={() => {
          setNoteHovered(false);
        }}
      />
    );
  }
  if (!hideNote) {
    return (
      <StringStyle
        onClick={playNote}
        onMouseEnter={() => {
          setNoteHovered(true);
        }}
        onMouseLeave={() => {
          setNoteHovered(false);
        }}
        ref={containerRef}
      >
        {selectedNote === 'None' && null}
        {hideNote && null}
        {selectedNote === 'All' && checkNaturalAccidental()}
        {selectedNote !== 'None' && selectedNote !== 'All'
          ? trueNote === selectedNote
            ? checkNaturalAccidental()
            : null
          : null}
        {noteHovered && (
          <Note>
            <Typography style={{ position: 'absolute' }}>
              {hideHover ? '' : trueNote}
            </Typography>
          </Note>
        )}
      </StringStyle>
    );
  }
}

String.propTypes = {
  stringIndex: PropTypes.number.isRequired,
  fretIndex: PropTypes.number.isRequired,
  note: PropTypes.string.isRequired,
  selectedNote: PropTypes.string.isRequired,
  hideNote: PropTypes.bool,
  accidentals: PropTypes.bool,
  naturals: PropTypes.bool,
  hideHover: PropTypes.bool.isRequired,
};

String.defaultProps = {
  hideNote: false,
  accidentals: true,
  naturals: true,
};

const StringStyle = styled.div`
  background-color: #c0c0c0;
  height: 2px;
  margin-top: 8px;
  margin-bottom: 8px;
  position: relative;
  cursor: pointer;
`;

const Note = styled.div`
  height: 20px;
  width: 20px;
  margin-left: 30px;
  margin-top: -12.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff4136;
  border: 1px solid #e6e6e6;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  position: absolute;
  z-index: 40;
  cursor: pointer;
  padding: 4px;
`;

const NoteLetters = styled(Typography)`
  position: absolute;
`;
