/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable react/require-default-props */
/* eslint-disable import/no-unresolved */
import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import { GuitarContext } from 'Contexts/GuitarContext';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from 'theme';
import { Box, Grid } from '@mui/material';
import { useSpring, animated } from 'react-spring';
import { v4 as uuidv4 } from 'uuid';

export function InstructionBox({
  lessonInstruction,
  setLessonCompleted,
  setLessonTime,
  setLessonAccuracy,
}) {
  const { noteClicked, setNoteClicked } = useContext(GuitarContext);
  const [noteNumber, setNoteNumber] = useState(0);
  const { instruction, ...noteToClick } = lessonInstruction[noteNumber];
  const [correctNoteClicked, setCorrectNoteClicked] = useState();
  const [startTime, setStartTime] = useState();

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const [state, toggle] = useState(false);
  const { x } = useSpring({
    from: { x: 0 },
    x: state ? 1 : 0,
    config: { duration: 1000 },
  });

  const wrongNotes = useRef([]);

  const formatedNote = useRef();

  useEffect(() => {
    // Reformats the note from API to be {stringIndex, note, fretIndex} when a correct note is clicked.
    formatedNote.current = Object.keys(noteToClick)
      .sort()
      .reduce((obj, key) => {
        obj[key] = noteToClick[key];
        return obj;
      }, {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteNumber]);

  useEffect(
    () => {
      if (noteClicked.note) {
        evaluateNoteClicked();
      }
      // Start timer when first note. Run again when last note clicked
      // This will run every time the note is clicked but that isn't really
      // a performance issue.
      if (noteNumber === 0) {
        setStartTime(new Date());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [noteClicked]
  );

  function getSecondsDiff(startDate, endDate) {
    const msInSecond = 1000;

    return Math.round(Math.abs(endDate - startDate) / msInSecond);
  }

  const evaluateNoteClicked = () => {
    if (JSON.stringify(noteClicked) === JSON.stringify(formatedNote.current)) {
      // Append color to the note and set correctly clicked on note
      lessonInstruction[noteNumber].backgroundcolor = '#e2f7e2';
      lessonInstruction[noteNumber].correctlyClicked = true;

      if (noteNumber === lessonInstruction.length - 1) {
        setNoteNumber(lessonInstruction.length - 1);
        forceUpdate();
        setTimeout(() => {
          // Lesson ends and we clear values.
          setLessonTime(getSecondsDiff(startTime, new Date()));
          setLessonAccuracy(
            wrongNotes.current.length / lessonInstruction.length
          );
          setLessonCompleted(true);
          setNoteNumber(0);
          setNoteClicked((prevState) => ({
            ...prevState,
            fretIndex: null,
            note: null,
            stringIndex: null,
          }));
        }, 3000);
      } else {
        setNoteNumber((prevState) => prevState + 1);
      }

      setCorrectNoteClicked('Correct');
    } else {
      // User clicked wrong note.
      // Add note to array if not alredy included
      if (!wrongNotes.current.includes(formatedNote.current)) {
        wrongNotes.current.push(formatedNote.current);
      }
      lessonInstruction[noteNumber].backgroundcolor = '#f5687c';
      setCorrectNoteClicked('Wrong');
      toggle(!state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <div>
      <NotesGrid container>
        {lessonInstruction.map((lessonItem, index) => {
          // If note was clicked correctly
          if (lessonItem.correctlyClicked) {
            return (
              <div key={lessonItem.fretIndex}>
                <NoteBoxDisplay
                  backgroundcolor={lessonItem.backgroundcolor}
                  correct={correctNoteClicked}
                  theme={theme}
                >
                  <div>{lessonItem.instruction}</div>
                </NoteBoxDisplay>
                <hr style={{ border: 'none' }}></hr>
              </div>
            );
          }

          // If the note in the array is the same as the one the user needs to
          // click (match by string and fret index)
          if (
            index === noteNumber &&
            lessonItem.fretIndex === noteToClick.fretIndex &&
            lessonItem.stringIndex === noteToClick.stringIndex
          ) {
            return (
              <animated.div
                key={lessonItem.fretIndex}
                style={{
                  opacity: x.to({ range: [0, 1], output: [1, 1] }),
                  scale: x.to({
                    range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
                    output: [1, 0.97, 0.9, 1.1, 0.9, 1.1, 1.03, 1],
                  }),
                }}
              >
                <NoteBoxDisplay
                  key={lessonItem.instruction}
                  backgroundcolor={lessonItem.backgroundcolor}
                  correct={correctNoteClicked}
                  theme={theme}
                >
                  <div>{lessonItem.instruction}</div>
                </NoteBoxDisplay>
                <hr />
              </animated.div>
            );
          }

          // Notes that are still to be correctly clicked
          return (
            <div key={uuidv4()}>
              <NoteBoxDisplay>
                <div>{lessonItem.instruction}</div>
              </NoteBoxDisplay>
              <hr style={{ border: 'none' }}></hr>
            </div>
          );
        })}
      </NotesGrid>
      {lessonInstruction[lessonInstruction.length - 1].correctlyClicked ? (
        <div style={{ height: 14 }} />
      ) : null}
    </div>
  );
}

InstructionBox.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  lessonInstruction: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    .isRequired,
  setLessonCompleted: PropTypes.func.isRequired,
  setLessonTime: PropTypes.func.isRequired,
  setLessonAccuracy: PropTypes.func.isRequired,
};

const NotesGrid = styled(Grid)`
  display: flex;
  align-items: top;
  text-align: center;
  margin: 30px;
  max-height: 50vh;
  min-height: 10vh;
  margin-bottom: 15px;
  max-width: 1000px;
  width: 95%;
  margin-top: 10vh;
  padding-bottom: 30px;
  overflow-y: scroll;

  /* Style the scrollbar */
  &::-webkit-scrollbar {
    width: 10px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
  }

  /* Hide the scrollbar when it's not needed */
  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
`;

const NoteBoxDisplay = styled(Box)`
  display: flex;
  border: 2px solid;
  border-color: ${(props) =>
    props.backgroundcolor
      ? props.backgroundcolor === '#e2f7e2'
        ? '#13c373'
        : 'red'
      : '#555'};
  border-radius: 10px;
  margin: 0px 10px;
  padding: 0 20px;
  font-size: 45px;
  font-family: ${() => theme.typography.fonts.AtkinsonHyperlegible};
  word-spacing: 10px;
  line-height: 1.6;
  color: ${(props) =>
    props.backgroundcolor
      ? props.backgroundcolor === '#e2f7e2'
        ? '#13c373'
        : 'white'
      : '#555'};
  background-color: ${(props) =>
    props.backgroundcolor ? props.backgroundcolor : null};
  opacity: 0.8;
  text-align: center;
  position: top;

  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
`;

// const EmptyIconWrapper = styled.div`
//   height: 30px;
//   width: 30px;

//   @media screen and (max-width: 768px) {
//     height: 20px;
//     width: 20px;
//   }
// `;
