import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import MenuIcon from '@mui/icons-material/Menu';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import MusicOffIcon from '@mui/icons-material/MusicOff';
import { GuitarContext } from 'Contexts/GuitarContext';

export function LessonNavbar({ lessonTitle, lessonNumber, lessonTutorial }) {
  const { soundOff, setSoundOff } = useContext(GuitarContext);
  const navigate = useNavigate();

  const toggleMusicOnOFF = () => {
    setSoundOff((prev) => !prev);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        style={{
          background: 'white',
          boxShadow: 'none',
          borderBottom: '1px solid black',
        }}
      >
        <Toolbar
          style={{
            minHeight: '20px',
          }}
        >
          <IconButton
            edge="start"
            color="primary"
            aria-label="menu"
            sx={{ mr: 2 }}
            size="large"
            onClick={() => navigate('/lessons')}
          >
            <MenuIcon />
          </IconButton>
          <AppBarItemsContainer>
            <GroupedItemsContainer>
              <AppBarTextItem>
                {`Lesson ${lessonNumber}: ${lessonTitle}`}
              </AppBarTextItem>
            </GroupedItemsContainer>
            <GroupedItemsContainer>
              <Tooltip title="Toggle sound.">
                <IconButton onClick={toggleMusicOnOFF}>
                  {soundOff ? <MusicOffIcon /> : <MusicNoteIcon />}
                </IconButton>
              </Tooltip>
              {!lessonTutorial && (
                <Tooltip title="To complete this lesson and progress to the next, click the correct notes on the guitar. If you can't remember the notes, try completing a previous lesson">
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              )}
            </GroupedItemsContainer>
          </AppBarItemsContainer>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

LessonNavbar.propTypes = {
  lessonTitle: PropTypes.string.isRequired,
  lessonNumber: PropTypes.string.isRequired,
  lessonTutorial: PropTypes.bool.isRequired,
};

const AppBarItemsContainer = styled(Box)`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
`;

const GroupedItemsContainer = styled(Box)`
  display: flex;
`;

const AppBarTextItem = styled(Typography)`
  flex-grow: 1;
  margin: 0 10px;
  font-size: 20px;
  font-weight: 400;
  color: black;
  cursor: default;

  @media (max-width: 600px) {
    font-size: 16px;
  }

  @media (max-width: 400px) {
    font-size: 14px;
    text-overflow: ellipsis; /* enables ellipsis */
    white-space: nowrap; /* keeps the text in a single line */
  }
`;
