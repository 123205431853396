/* eslint-disable no-plusplus */
/* eslint-disable arrow-body-style */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React, { useState, useEffect, useMemo } from 'react';
import BackgroundImage from 'assets/img/1800x3389.png';
import { LessonsGridItem } from 'Components/Lessons/LessonsGridItem';
import { Navbar } from 'Components/Navbar/Navbar';
import { LessonModal } from 'Components/Lessons/LessonModal';

import { Container, Grid } from '@mui/material';

import { UserAuth } from 'Contexts/AuthContext';

import {
  collection,
  getDocs,
  query,
  orderBy,
  getDoc,
  doc,
} from 'firebase/firestore';
import { db } from 'firebase-config.js';
import styled from 'styled-components';
import { LoadingComponent } from 'Components/Loading/LoadingComponent';

export const LAST_DEFAULT_LESSON = 24;

export function Lessons() {
  const { user } = UserAuth();

  const [topics, setTopics] = useState(null);
  const [lessons, setLessons] = useState(null);
  const [completedLessons, setCompletedLessons] = useState(null);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  const queryTopics = query(collection(db, 'Topics'), orderBy('order', 'asc'));
  const queryLessons = query(
    collection(db, 'Lessons'),
    orderBy('lessonNumber', 'asc')
  );

  useEffect(() => {
    const getTopics = async () => {
      const querySnapshot = await getDocs(queryTopics);
      setTopics(
        querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    };
    getTopics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getLessons = async () => {
      const querySnapshot = await getDocs(queryLessons);
      setLessons(
        querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    };
    getLessons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getCompletedLessons = async () => {
      const userRef = doc(db, 'Users', user.uid);
      const docSnap = await getDoc(userRef);

      if (docSnap.exists()) {
        setCompletedLessons(docSnap.get('completedLessons'));
      }
    };
    if (user.uid) {
      getCompletedLessons();
    }
  }, [user]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const highestAvailableLessonNumber = useMemo(() => {
    // This memo computes the highest available lesson.
    // Calculated by adding 1 to the highest completed lesson
    let highestNumber = LAST_DEFAULT_LESSON;

    if (completedLessons) {
      lessons?.forEach((lesson) => {
        if (completedLessons.includes(lesson.id)) {
          highestNumber = lesson.lessonNumber + 1;
        }
      });
    }

    return highestNumber;
  }, [lessons, completedLessons]);

  if (!lessons || !topics) {
    return <LoadingComponent />;
  }

  return (
    <>
      <Navbar lessonsNavbar />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Background style={{ transform: `translateY(-${offsetY * 0.5}px)` }} />
        <Container
          style={{
            marginTop: '50px',
          }}
        >
          {topics?.map((topic) => {
            return (
              <div key={topic.id} style={{ padding: '10px' }}>
                <TopicTitle>{topic.name}</TopicTitle>
                <LessonsGrid container>
                  {lessons?.map((lesson) => {
                    if (lesson.topic === topic.name) {
                      let completed = false;
                      // Check if lesson completed
                      if (completedLessons?.includes(lesson.id)) {
                        completed = true;
                      }

                      // Check if lesson available (less than highest available lesson)
                      let availableLesson =
                        lesson.lessonNumber <= highestAvailableLessonNumber;

                      return (
                        <LessonsGridItem
                          lesson={lesson}
                          availableLesson={availableLesson}
                          completed={completed}
                          handleOpen={handleOpen}
                          key={lesson.lessonNumber}
                        />
                      );
                    }
                    return null;
                  })}
                </LessonsGrid>
              </div>
            );
          })}
        </Container>
        <LessonModal open={open} handleClose={handleClose} />
      </div>
    </>
  );
}

const LessonsGrid = styled(Grid)`
  justify-content: flex-start;

  @media (max-width: 600px) {
    justify-content: center;
  }
`;

const TopicTitle = styled.div`
  margin-top: 50px;
  min-width: 180px;
  padding-left: 10px;
  font-weight: 700;
  font-size: 2em;
  text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
  font-family: Atkinson Hyperlegible;
`;

const Background = styled.div`
  background-image: url(${BackgroundImage});
  width: 1800px;
  height: 3689px;
  position: fixed;
  background-repeat: no-repeat;
  z-index: -1;
`;
