import React, { useEffect, useState } from 'react';
import { Container } from '@mui/system';
import {
  Typography,
  Paper,
  Box,
  Grid,
  TextField,
  Button,
  Link,
  Divider,
} from '@mui/material';
import { GoogleButton } from 'react-google-button';
import { UserAuth } from 'Contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { AuthNavbar } from './AuthNavbar';
export function Register() {
  const navigate = useNavigate();

  const { register, user, googleSignIn } = UserAuth();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [registerEmail, setRegisterEmail] = useState('');
  const [registerPassword, setRegisterPassword] = useState('');
  const [error, setError] = useState('');

  const registerDisabled = !firstName || !registerEmail || !registerPassword;

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await register(registerEmail, registerPassword, firstName, lastName);
    } catch (e) {
      setError(e.message);
      console.log(e.message);
    }
  };

  useEffect(() => {
    if (user.displayName) {
      navigate('/lessons');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.displayName]);

  return (
    <>
      <AuthNavbar />
      <Container component="main" maxWidth="sm">
        <StyledPaper elevation={15}>
          <Typography component="h1" variant="h5">
            Register
          </Typography>
          <Grid
            container
            sx={{ mb: 3 }}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12}>
              <GoogleButton
                label="Register with Google"
                onClick={handleGoogleSignIn}
              />
            </Grid>
          </Grid>
          <Divider flexItem>OR</Divider>
          <Box component="form" noValidate sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  onChange={(event) => setFirstName(event.target.value)}
                  helperText="Name is required"
                  error={error}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  onChange={(event) => setLastName(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={(event) => setRegisterEmail(event.target.value)}
                  helperText="Email is required"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={(event) => setRegisterPassword(event.target.value)}
                  helperText="Password is required"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={registerDisabled}
              sx={{ mt: 3, mb: 2 }}
              onClick={(e) => handleSubmit(e)}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                Already have an account?
                <Link href="/login"> Sign in</Link>
              </Grid>
            </Grid>
          </Box>
        </StyledPaper>
      </Container>
    </>
  );
}

const StyledPaper = styled(Paper)`
  padding: 50px 50px;
`;
