/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { LessonNavbar } from 'Components/Navbar/LessonNavbar';
import { GuitarContext } from 'Contexts/GuitarContext';
import { useParams } from 'react-router';
import { GuitarV2 } from 'Components/Guitar/GuitarV2';
import { Paper } from '@mui/material';
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  arrayUnion,
} from 'firebase/firestore';
import { db } from 'firebase-config.js';
import { useLocation } from 'react-router-dom';
import { LoadingComponent } from 'Components/Loading/LoadingComponent';
// mui/system is the LEGACY verison
// I want to be using mui/material which is the new verison
// styled should come from @mui/material/styles
import { styled } from '@mui/system';
import { InstructionBox } from './InstructionBox';
import { DescriptionBox } from './DescriptionBox';
import { LessonSummary } from './LessonSummary';
import { BottomBar } from './BottomBar';
import { UserAuth } from 'Contexts/AuthContext';

export function Lesson() {
  const location = useLocation();
  const { id: lessonNumber } = useParams();
  const { user } = UserAuth();
  const [noteClicked, setNoteClicked] = useState({
    fretIndex: null,
    note: null,
    stringIndex: null,
  });
  const [lesson, setLesson] = useState({});
  const [lessonCompleted, setLessonCompleted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [lessonTime, setLessonTime] = useState();
  const [lessonAccuracy, setLessonAccuracy] = useState();
  const [soundOff, setSoundOff] = useState(false);
  const [retakeLesson, setRetakeLesson] = useState(false);

  // Check if user is being redirected to retake the lesson
  useEffect(() => {
    if (location.state?.retake) {
      setRetakeLesson(true);
    }
  }, [location.state]);

  // Query the database when the lesson is started.
  useEffect(() => {
    setLoading(true);

    const getLesson = async () => {
      const q = query(
        collection(db, 'Lessons'),
        // eslint-disable-next-line radix
        where('lessonNumber', '==', parseInt(lessonNumber))
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setLesson({ id: doc.id, ...doc.data() });
      });
    };

    getLesson();
    return () => {
      setLesson([]);
      setNoteClicked({ fretIndex: null, note: null, stringIndex: null });
      setLessonCompleted(false);
      setRetakeLesson(false);
    };
  }, [lessonNumber, retakeLesson]);

  useEffect(() => {
    setTimeout(() => {
      if (lesson.name) {
        setLoading(false);
      }
    }, [2200]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lesson.name]);

  // If lesson is a tutorial, add it to the users completed lessons array
  useEffect(() => {
    if (lesson.tutorial && user.uid) {
      const userRef = doc(db, 'Users', user.uid);
      updateDoc(userRef, {
        completedLessons: arrayUnion(lesson.id),
      });
    }
  }, [lesson, user.uid]);

  if (loading) {
    return <LoadingComponent />;
  }

  const timeStringToSeconds = (timeString) => {
    const splitTimeArray = timeString.split(':');
    const seconds =
      +splitTimeArray[0] * 60 * 60 +
      +splitTimeArray[1] * 60 +
      +splitTimeArray[2];
    return seconds;
  };

  if (!loading && !lessonCompleted) {
    return (
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      <GuitarContext.Provider
        value={{ noteClicked, setNoteClicked, soundOff, setSoundOff }}
      >
        <LessonNavbar
          lessonTutorial={lesson?.tutorial ?? false}
          lessonNumber={lesson?.lessonNumber}
          lessonTitle={lesson?.name}
        />
        <StringBanner>{lesson?.topic}</StringBanner>
        <PageContainer>
          {lesson?.tutorial && (
            <DescriptionBox lessonDescription={lesson?.description} />
          )}
          {lesson?.notes ? (
            <InstructionBox
              lessonInstruction={lesson?.notes}
              setLessonCompleted={setLessonCompleted}
              setLessonTime={setLessonTime}
              setLessonAccuracy={setLessonAccuracy}
            />
          ) : null}
          <GuitarContainer>
            <GuitarV2
              fretCount={lesson?.fretCount ?? 12}
              selectedNote={lesson?.selectedNote ?? 'All'}
              selectedString={lesson?.selectedString ?? 'None'}
              openString={lesson?.openString ?? true}
              accidentals={lesson?.accidentals ?? true}
              naturals={lesson?.naturals ?? true}
              hideHover={lesson?.hideHover ?? false}
            />
          </GuitarContainer>
          {lesson?.tutorial ? (
            <BottomBar lessonNumber={Number(lessonNumber)} tutorial />
          ) : (
            <BottomBar lessonNumber={Number(lessonNumber)} />
          )}
        </PageContainer>
      </GuitarContext.Provider>
    );
  }

  if (!loading && lessonCompleted) {
    return (
      <GuitarContext.Provider
        value={{ noteClicked, setNoteClicked, soundOff, setSoundOff }}
      >
        <LessonNavbar
          lessonTutorial={lesson?.tutorial ?? false}
          lessonTopic={lesson?.topic}
          lessonTitle={lesson?.name}
        />

        <LessonSummary
          lesson={lesson}
          lessonNumber={Number(lessonNumber)}
          lessonCompleted={lessonCompleted}
          setLessonCompleted={setLessonCompleted}
          lessonTime={lessonTime}
          lessonAccuracy={lessonAccuracy}
          completionLimit={timeStringToSeconds(lesson?.completionLimit)}
        />
      </GuitarContext.Provider>
    );
  }
}

const PageContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  min-width: 100%;
  background: white;
`;

const GuitarContainer = styled('div')`
  width: 95vw;
`;

const StringBanner = styled(Paper)`
  background: #5293e2;
  color: white;
  width: 75px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  text-align: center;
  text-transform: uppercase;
  font-family: arial;
  font-size: 14px;
  padding: 160px 5px 8px 5px;
  position: fixed;
  z-index: 29;
  left: 8%;
  margin-left: -7.5px;
  @media (max-width: 1325px) {
    display: none;
  }
`;
