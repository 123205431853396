import React from 'react';
import PropTypes from 'prop-types';
import { Neck } from './Neck';

export function GuitarV2({
  fretCount,
  selectedNote,
  selectedString,
  openString,
  accidentals,
  naturals,
  hideHover,
}) {
  return (
    <div>
      <Neck
        fretCount={fretCount}
        selectedNote={selectedNote}
        selectedString={selectedString}
        showOpenString={openString}
        accidentals={accidentals}
        naturals={naturals}
        hideHover={hideHover}
      />
    </div>
  );
}

GuitarV2.propTypes = {
  fretCount: PropTypes.number.isRequired,
  selectedNote: PropTypes.string.isRequired,
  selectedString: PropTypes.string.isRequired,
  openString: PropTypes.bool.isRequired,
  accidentals: PropTypes.bool.isRequired,
  naturals: PropTypes.bool.isRequired,
  hideHover: PropTypes.bool.isRequired,
};
