/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */
import React from 'react';
// eslint-disable-next-line import/no-unresolved
import LoadingGIF from 'assets/gifs/guitarGIF.gif';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const LoadingComponent = () => {
  return (
    <LoadingComponentWraper>
      <LoadingComponetStyle>
        <img src={LoadingGIF} alt="Loading..." />
      </LoadingComponetStyle>
    </LoadingComponentWraper>
  );
};

// Currently no way of changing size of GIF
// Might need to revisit this
const LoadingComponetStyle = styled(Box)``;

const LoadingComponentWraper = styled('div')`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
