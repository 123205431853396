import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router';
import { UserAuth } from 'Contexts/AuthContext';
import { useParams } from 'react-router';
import { Navigate } from 'react-router-dom';

import { LAST_DEFAULT_LESSON } from 'Components/Lessons/Lessons';

import {
  collection,
  query,
  where,
  getDocs,
  doc,
  onSnapshot,
} from 'firebase/firestore';
import { db } from 'firebase-config.js';
import { LoadingComponent } from 'Components/Loading/LoadingComponent';

export const ProtectedLessonRoute = () => {
  const { user, userLoading } = UserAuth();
  const { id: lessonNumber } = useParams();

  const [competedLessons, setCompletedLessons] = useState();
  const [lesson, setLesson] = useState();

  // Listen for changes to the `completedLessons` field in Firestore for the current user.
  // Update the `competedLessons` state with the latest completed lessons whenever the `completedLessons` field changes.
  // This ensures that the component always has the latest completed lessons, even if the user completes a lesson and navigates to the next lesson.
  useEffect(() => {
    if (user.uid) {
      const userRef = doc(db, 'Users', user.uid);
      const unsubscribe = onSnapshot(userRef, (docSnap) => {
        if (docSnap.exists()) {
          setCompletedLessons(docSnap.get('completedLessons'));
        }
      });
      return () => unsubscribe();
    }
  }, [user]);

  // Get the previous lesson to the lessonNumber param.
  useEffect(() => {
    const getLesson = async () => {
      // Check if frist lesson. Otherwise subtract number to query previous lesson
      let lessonNumberToQuery =
        lessonNumber === '1'
          ? parseInt(lessonNumber)
          : parseInt(lessonNumber - 1);

      const q = query(
        collection(db, 'Lessons'),
        // eslint-disable-next-line radix
        where('lessonNumber', '==', lessonNumberToQuery)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setLesson({ id: doc.id, ...doc.data() });
      });
    };

    getLesson();
  }, [lessonNumber]);

  if (userLoading) {
    return <LoadingComponent />;
  }
  // Check if user logged in.
  // If they aren't keep lessons past 29 locked.
  if (!user.uid && lessonNumber <= LAST_DEFAULT_LESSON) {
    return <Outlet />;
  } else if (!user.uid && lessonNumber > LAST_DEFAULT_LESSON) {
    return <Navigate to="lessons" />;
  }

  if (!competedLessons | !lesson) {
    return <LoadingComponent />;
  }

  // Check if lessonId is in the users completed lessons
  if (
    competedLessons.includes(lesson.id) ||
    lessonNumber <= LAST_DEFAULT_LESSON
  ) {
    return <Outlet />;
  } else {
    return <Navigate to="lessons" />;
  }
};
