/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable import/no-unresolved */
import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { GuitarContext } from 'Contexts/GuitarContext';
import GuitarImage from 'assets/img/GuitarImg400.png';
import CloudImage from 'assets/img/Ultrawideclouds180px.png';
import { Container, Grid, Box, Button, Typography } from '@mui/material';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import { Guitar } from 'Components/Guitar/HomepageGuitar';
import { Navbar } from 'Components/Navbar/Navbar';
import { AdComponent } from 'Components/Adsense/AdComponent';

export function HomePage() {
  const navigate = useNavigate();
  const bottomRef = useRef(null);

  const [noteClicked, setNoteClicked] = useState({
    fretIndex: null,
    note: null,
    stringIndex: null,
  });

  return (
    <>
      <Navbar />
      <div
        style={{
          display: 'flex',
          minHeight: '100vh',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Container
          style={{
            minWidth: '80vw',
          }}
        >
          <GetStartedContainer container>
            <GuitarImageGridItem
              component={Grid}
              item
              display={{ xs: 'none', md: 'block' }}
              sm={12}
              md={5}
              lg={5}
              xl={5}
            />
            <Grid item xs={10} sm={8} md={6} lg={6} xl={6}>
              <LearnGuitarTitle variant="h4">
                Master the Fretboard for Free!
                <MusicNoteStyle />
              </LearnGuitarTitle>
              <LargeGetStartedButton
                variant="contained"
                onClick={() => {
                  navigate('/lessons');
                }}
              >
                GET STARTED
              </LargeGetStartedButton>
            </Grid>
            <Grid item xs={12}>
              <GuitarContext.Provider value={{ noteClicked, setNoteClicked }}>
                <Guitar />
              </GuitarContext.Provider>
            </Grid>
          </GetStartedContainer>
        </Container>
        <AdComponent />
        <div ref={bottomRef} />
        <Cloudbackground />
      </div>
    </>
  );
}

// const PageWrapper = styled(Box)`
//   display: flex;
//   flex-direction: column;
//   width: 100%;
//   min-height: 100vh;
//   background: #88d4ee;
// `;

const GetStartedContainer = styled(Grid)({
  minHeight: '778px',
  direction: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '200px',
});

const GuitarImageGridItem = styled(Box)({
  backgroundImage: `url(${GuitarImage})`,
  backgroundRepeat: `no-repeat`,
  width: `400px`,
  height: `400px`,
});

const MusicNoteStyle = styled(MusicNoteIcon)({
  fontSize: '35px',
});

// const LargeGetStartedButton = styled(Button)({
//   marginTop: '10px',
//   minHeight: '45px',
// });

const LargeGetStartedButton = styled(Button, {
  // Configure which props should be forwarded on DOM
  name: 'MuiButtons',
  slot: 'Root',
})({
  marginTop: '10px',
  minHeight: '45px',
});

const LearnGuitarTitle = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.boldH3Title.fontWeight,
}));

const Cloudbackground = styled('div')({
  backgroundImage: `url(${CloudImage})`,
  backgroundRepeat: 'no-repeat',
  height: '180px',
  width: '100%',
});
