import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`

html{
  font-family: Atkinson Hyperlegible;
}

body {
  background: #88D4EE;
  margin: 0;
  overflow-x: hidden;
}

`;

export default GlobalStyles;
