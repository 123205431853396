/* eslint-disable react/require-default-props */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from 'theme';

export function DescriptionBox({ lessonDescription }) {
  return <TextBox theme={theme}>{lessonDescription}</TextBox>;
}

const TextBox = styled(Box)`
  margin: 30px;
  max-height: 50vh;
  min-height: 10vh;
  margin-bottom: 15px;
  max-width: 900px;
  width: 90%;
  margin-top: 10vh;
  padding-bottom: 30px;
  overflow-y: scroll;
  font-size: 35px;
  font-family: ${() => theme.typography.fonts.AtkinsonHyperlegible};
  word-spacing: 10px;
  line-height: 1.6;
  color: #555;

  @media (max-width: 600px) {
    font-size: 28px;
    padding: 0 10px 0 0;
  }

  /* Style the scrollbar */
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
  }

  /* Hide the scrollbar when it's not needed */
  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
`;

DescriptionBox.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  lessonDescription: PropTypes.string.isRequired,
};
