import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import styled from 'styled-components';
import { UserAuth } from 'Contexts/AuthContext';
import { AccountMenu } from './AccountMenu';
import { Link } from '@mui/material';

export function Navbar({ lessonsNavbar }) {
  const navigate = useNavigate();
  const { user } = UserAuth();

  return (
    <MainAppBar $lesson={lessonsNavbar} component="nav">
      <Toolbar>
        <LogoTitle
          $lesson={lessonsNavbar}
          onClick={() => {
            navigate('/');
          }}
        >
          FretBuddy
        </LogoTitle>
        <NavItems>
          {user?.displayName ? (
            <>
              <DisplayName $lesson={lessonsNavbar}>
                {user?.displayName}
              </DisplayName>
              <AccountMenu />
            </>
          ) : (
            <>
              <SignInLink
                $lesson={lessonsNavbar}
                href="/login"
                underline="none"
              >
                Sign In
              </SignInLink>
              <SignUpButton
                $lesson={lessonsNavbar}
                variant="contained"
                color="primary"
                onClick={() => navigate('/register')}
              >
                Sign Up
              </SignUpButton>
            </>
          )}
        </NavItems>
      </Toolbar>
    </MainAppBar>
  );
}

Navbar.propTypes = {
  // eslint-disable-next-line react/require-default-props
  lessonsNavbar: PropTypes.bool,
};

const NavItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DisplayName = styled(Typography)`
  color: ${(props) => (props.$lesson ? '#ffffffcc' : '#0C2D48')};
`;

const SignInLink = styled(Link)`
  padding: 0 16px;
  color: ${(props) => (props.$lesson ? '#ffffffcc' : '#0C2D48')};
  &:hover {
    color: white;
  }
`;

const SignUpButton = styled(Button)`
  background-color: ${(props) => (props.$lesson ? 'white' : '#0C2D48')};
  color: ${(props) => (props.$lesson ? '#0C2D48' : 'white')};
  &:hover {
    color: white;
  }
`;

const MainAppBar = styled(AppBar)`
  background: ${(props) => (props.$lesson ? '#283138' : '#88D4EE;')};
  box-shadow: none;
  margin-bottom: 10vh;
`;

const LogoTitle = styled(Typography)`
  flex-grow: 1;
  font-size: 25px;
  font-weight: 700;
  cursor: pointer;
  color: ${(props) => (props.$lesson ? 'white' : '#0C2D48')};
`;
