import React, { useEffect } from 'react';

export const AdComponent = () => {
  useEffect(() => {
    window.adsbygoogle = window.adsbygoogle || [];
    window.adsbygoogle.push({});
  }, []);

  return (
    <div>
      <ins
        className="adsbygoogle"
        style={{
          display: 'inline-block',
          height: '200px',
        }}
        data-ad-client="ca-pub-1234567890123456"
        data-ad-slot="1234567890"
      />
    </div>
  );
};
