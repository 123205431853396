import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid } from '@mui/material';
import { MiddleFret } from './MiddleFret';
import { FretDot } from './FretDot';
import { DoubleFretDotBottom } from './DoubleFretDotBottom';
import { DoubleFretDotTop } from './DoubleFretDotTop';

import { Nut } from './Nut';

const NeckStyle = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
`;

const NoteMap = [
  ['F4', 'C4', 'GsAb3', 'DsEb3', 'AsBb2', 'F2'],
  ['FsGb4', 'CsDb4', 'A3', 'E3', 'B2', 'FsGb2'],
  ['G4', 'D4', 'AsBb3', 'F3', 'C3', 'G2'],
  ['GsAb4', 'DsEb4', 'B3', 'FsGb3', 'CsDb3', 'GsAb2'],
  ['A4', 'E4', 'C4', 'G3', 'D3', 'A2'],
  ['AsBb4', 'F4', 'CsDb4', 'GsAb3', 'DsEb3', 'AsBb2'],
  ['B4', 'FsGb4', 'D4', 'A3', 'E3', 'B2'],
  ['C5', 'G4', 'DsEb4', 'AsBb3', 'F3', 'C3'],
  ['CsDb5', 'GsAb4', 'E4', 'B3', 'FsGb3', 'CsDb3'],
  ['D5', 'A4', 'F4', 'C4', 'G3', 'D3'],
  ['DsEb5', 'AsBb4', 'FsGb4', 'CsDb4', 'GsAb3', 'DsEb3'],
  ['E5', 'B4', 'G4', 'D4', 'A3', 'E3'],
  ['F5', 'C5', 'GsAb4', 'DsEb4', 'AsBb3', 'F3'],
  ['FsGb5', 'CsDb5', 'A4', 'E4', 'B3', 'FsGb3'],
  ['G5', 'D5', 'Bb4', 'F4', 'C4', 'G3'],
  ['Ab5', 'DsEb5', 'B4', 'FsGb4', 'CsDb4', 'GsAb3'],
];

export function Neck({
  fretCount,
  selectedNote,
  selectedString,
  showOpenString,
  accidentals,
  naturals,
  hideHover,
}) {
  const NeckLength = Array.from({ length: fretCount }, (v, i) => i);

  return (
    <Grid
      container
      spacing={0}
      marginTop={5}
      marginBottom={3}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ overflowX: 'auto', overflowY: 'hidden' }}
    >
      <Grid item xs={3}>
        <NeckStyle>
          <Nut
            fretIndex={0}
            showOpenString={showOpenString}
            hideHover={hideHover}
          />
          {NeckLength.map((fretNumber, index) => {
            if ([2, 4, 6, 8].includes(index)) {
              return (
                <div key={fretNumber}>
                  <FretDot />
                  <MiddleFret
                    fretIndex={index + 1}
                    notes={NoteMap[fretNumber]}
                    selectedNote={selectedNote}
                    selectedString={selectedString}
                    accidentals={accidentals}
                    naturals={naturals}
                    hideHover={hideHover}
                  />
                </div>
              );
            }
            if ([11].includes(index)) {
              return (
                <div key={fretNumber}>
                  <DoubleFretDotTop />
                  <DoubleFretDotBottom />
                  <MiddleFret
                    fretIndex={index + 1}
                    notes={NoteMap[fretNumber]}
                    selectedNote={selectedNote}
                    selectedString={selectedString}
                    accidentals={accidentals}
                    naturals={naturals}
                    hideHover={hideHover}
                  />
                </div>
              );
            }
            return (
              <MiddleFret
                fretIndex={index + 1}
                key={fretNumber}
                notes={NoteMap[fretNumber]}
                selectedNote={selectedNote}
                selectedString={selectedString}
                accidentals={accidentals}
                naturals={naturals}
                hideHover={hideHover}
              />
            );
          })}
        </NeckStyle>
      </Grid>
    </Grid>
  );
}

Neck.propTypes = {
  fretCount: PropTypes.number.isRequired,
  selectedNote: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  selectedString: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  showOpenString: PropTypes.bool.isRequired,
  accidentals: PropTypes.bool.isRequired,
  naturals: PropTypes.bool.isRequired,
  hideHover: PropTypes.bool.isRequired,
};
