/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { BottomBar } from './BottomBar';
import { useEffect } from 'react';
import { db } from 'firebase-config.js';
import { UserAuth } from 'Contexts/AuthContext';
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  addDoc,
  doc,
  updateDoc,
  arrayUnion,
} from 'firebase/firestore';

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export function LessonSummary({
  lessonNumber,
  lessonTime,
  lessonAccuracy,
  completionLimit,
  lessonCompleted,
}) {
  const { user } = UserAuth();
  const [lessonId, setLessonId] = useState();

  const FormattedTime = useMemo(() => {
    const minutes = Math.floor(lessonTime / 60);
    const seconds = lessonTime % 60;

    return `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
  }, [lessonTime]);

  const Accuracy = useMemo(() => {
    return Math.round(100 - lessonAccuracy * 100);
  }, [lessonAccuracy]);

  const Score = useMemo(() => {
    return Math.round(
      (completionLimit / lessonTime) * (1 - lessonAccuracy) * 100
    );
  }, [completionLimit, lessonTime, lessonAccuracy]);

  useEffect(() => {
    const getLessonId = async () => {
      // Query Lessons by lesson number and update lessonId in state
      const queryLessons = query(
        collection(db, 'Lessons'),
        where('lessonNumber', '==', Number(lessonNumber))
      );

      const lessonsQuerySnapshot = await getDocs(queryLessons);

      lessonsQuerySnapshot.forEach(async (document) =>
        setLessonId(document.id)
      );
    };
    getLessonId();
  }, [lessonNumber]);

  useEffect(() => {
    const updateLessonHighScore = async () => {
      // Query LessonHighScore by user uid and lesson id
      const lessonsHighScoreQuery = query(
        collection(db, 'LessonHighScore'),
        where('lessonId', '==', lessonId),
        where('userUid', '==', user.uid)
      );

      const highScoreQuerySnapshot = await getDocs(lessonsHighScoreQuery);

      if (highScoreQuerySnapshot.empty) {
        // Create a new LessonHighScore document if first time completing the lesson.
        await addDoc(collection(db, 'LessonHighScore'), {
          lessonAccuracy: Number((1 - lessonAccuracy).toFixed(2)),
          lessonDuration: FormattedTime,
          lessonId: lessonId,
          lessonNumber: Number(lessonNumber),
          lessonScore: Score,
          userUid: user.uid,
        });
      } else {
        // Update the LessonHighScore if the user scored higher than previously
        highScoreQuerySnapshot.forEach(async (document) => {
          const documentId = document.id;
          const prevHighScore = document.data().lessonScore;
          if (Score > prevHighScore) {
            await setDoc(doc(db, 'LessonHighScore', documentId), {
              lessonAccuracy: Number((1 - lessonAccuracy).toFixed(2)),
              lessonDuration: FormattedTime,
              lessonId: lessonId,
              lessonNumber: Number(lessonNumber),
              lessonScore: Score,
              userUid: user.uid,
            });
          }
        });
      }
    };

    const updateUserCompletedLessons = async () => {
      // Query LessonHighScore by user uid and lesson id
      const lessonsHighScoreQuery = query(
        collection(db, 'LessonHighScore'),
        where('lessonId', '==', lessonId),
        where('userUid', '==', user.uid)
      );

      const highScoreQuerySnapshot = await getDocs(lessonsHighScoreQuery);

      // If user has a high score on that lesson, add lesson to completedLessons array on user document
      if (!highScoreQuerySnapshot.empty) {
        highScoreQuerySnapshot.forEach(async (document) => {
          const userRef = doc(db, 'Users', user.uid);
          await updateDoc(userRef, {
            completedLessons: arrayUnion(lessonId),
          });
        });
      }
    };
    if (lessonId) {
      updateLessonHighScore().then(() => updateUserCompletedLessons());
    }
  }, [
    user,
    Accuracy,
    FormattedTime,
    Score,
    lessonAccuracy,
    lessonNumber,
    lessonId,
  ]);

  return (
    <PageContainer>
      <ContentWrapper>
        <ScoreContainer>
          <CircleWrapper>
            <SummaryCircle>{FormattedTime}s</SummaryCircle>
            <ScoreTitle>Duration</ScoreTitle>
          </CircleWrapper>
          <ScoreWrapper>
            <SummaryCircle primary>{Score}</SummaryCircle>
            <ScoreTitle>Score</ScoreTitle>
          </ScoreWrapper>
          <CircleWrapper>
            <SummaryCircle>{Accuracy}%</SummaryCircle>
            <ScoreTitle>Accuracy</ScoreTitle>
          </CircleWrapper>
        </ScoreContainer>
      </ContentWrapper>
      <BottomBar
        lessonNumber={lessonNumber}
        lessonCompleted={lessonCompleted}
      />
    </PageContainer>
  );
}

LessonSummary.propTypes = {
  setLessonCompleted: PropTypes.func.isRequired,
  lessonCompleted: PropTypes.bool,
  lessonNumber: PropTypes.number.isRequired,
  lessonTime: PropTypes.number.isRequired,
  lessonAccuracy: PropTypes.number.isRequired,
  completionLimit: PropTypes.number.isRequired,
};

const PageContainer = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const ScoreContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  marginBottom: theme.spacing(4),
  gap: theme.spacing(5),

  [theme.breakpoints.up('xs')]: {
    flexDirection: 'column',
  },

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}));

const CircleWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const ScoreWrapper = styled(CircleWrapper)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    position: 'relative',
    bottom: '50px',
  },
}));

const SummaryCircle = styled(Box)(({ theme, primary }) => ({
  width: '140px',
  height: '140px',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: primary
    ? theme.palette.primary.main
    : theme.palette.secondary.main,
  color: theme.palette.common.white,
  fontSize: theme.typography.h3.fontSize,

  [theme.breakpoints.up('xs')]: {
    width: '140px',
    height: '140px',
    fontSize: theme.typography.h4.fontSize,
  },
  [theme.breakpoints.up('sm')]: {
    width: '200px',
    height: '200px',
    fontSize: theme.typography.h3.fontSize,
  },
}));
const ScoreTitle = styled('div')(({ theme }) => ({
  fontWeight: '700',
  fontSize: '1.8em',
  textShadow: '-2px 0 white, 0 2px white, 2px 0 white, 0 -2px white',
  fontFamily: theme.typography.fonts.AtkinsonHyperlegible,
}));
