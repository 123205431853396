import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { OpenNote } from './OpenNote';

const OpenNotes = ['E4', 'B3', 'G3', 'D3', 'A2', 'E2'];

export function Nut({ fretIndex, showOpenString, hideHover }) {
  return (
    <div>
      <NutStyle>
        {OpenNotes.map((note, index) => (
          <NutString key={note}>
            <OpenNote
              showOpenString={showOpenString}
              fretIndex={fretIndex}
              note={note}
              stringIndex={index + 1}
              hideHover={hideHover}
            />
          </NutString>
        ))}
      </NutStyle>
    </div>
  );
}

Nut.propTypes = {
  fretIndex: PropTypes.number.isRequired,
  showOpenString: PropTypes.bool.isRequired,
  hideHover: PropTypes.bool.isRequired,
};

const NutStyle = styled.div`
  background-color: #f8e7b2;
  background-size: 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px 0px 10px 0px;
  width: 40px;
  height: 180px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
`;

const NutString = styled.div`
  background-color: #c0c0c0;
  width: 50px;
  height: 2px;
  position: relative;
  margin-top: 8px;
  margin-bottom: 8px;
`;
