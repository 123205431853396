/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { LessonContext } from 'Contexts/LessonContext';
import { Routes, Route } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { AuthContextProvider } from 'Contexts/AuthContext';
import customTheme from 'theme';
import GlobalStyles from 'Styles/GlobalStyles';
import { Lessons } from 'Components/Lessons/Lessons';
import { Lesson } from 'Components/Lesson/Lesson';
import { Register } from 'Components/Authentication/Register';
import { SignIn } from 'Components/Authentication/SignIn';
import { HomePage } from './Pages/HomeV2';
import { ProtectedLessonRoute } from 'ProtectedRoutes/ProtectedLessonRoute';

function App() {
  const [lessonIdContext, setLessonIdContext] = useState();

  return (
    <>
      <GlobalStyles />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={customTheme}>
          <AuthContextProvider>
            <LessonContext.Provider
              value={{ lessonIdContext, setLessonIdContext }}
            >
              <Routes>
                <Route element={<ProtectedLessonRoute />}>
                  <Route path="lessons/:id" element={<Lesson />} />
                </Route>
                <Route path="login" element={<SignIn />} />
                <Route path="/" element={<HomePage />} />
                <Route path="register" element={<Register />} />
                <Route path="lessons" element={<Lessons />} />
              </Routes>
            </LessonContext.Provider>
          </AuthContextProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
}

export default App;
