/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */
import React from 'react';
import { Box, Button } from '@mui/material';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';

export const BottomBar = ({ lessonNumber, tutorial, lessonCompleted }) => {
  const navigate = useNavigate();

  const handleNavigateNextLesson = () => {
    navigate(`/lessons/${Number(lessonNumber) + 1}`);
  };

  const handleNavigateSameLesson = () => {
    navigate(`/lessons/${Number(lessonNumber)}`, { state: { retake: true } });
  };

  const handleNavigatePrevLesson = () => {
    navigate(`/lessons/${Number(lessonNumber) - 1}`);
  };

  const handleNavigateLessons = () => {
    navigate(`/lessons`);
  };

  return (
    <BottomBarStyling>
      <ButtonWrapper>
        {!tutorial && (
          <TryAgainButton
            variant="correct"
            color="primary"
            shape="rounded"
            onClick={handleNavigateSameLesson}
          >
            Try Again
          </TryAgainButton>
        )}
        {Number(lessonNumber) === 1 && (
          <BackToLessonsButton
            shape="rounded"
            variant="outlined"
            onClick={handleNavigateLessons}
          >
            <ArrowCircleLeftIcon />
            Back to lessons
          </BackToLessonsButton>
        )}
        {tutorial && Number(lessonNumber) !== 1 && (
          <PreviousButton
            shape="rounded"
            variant="outlined"
            onClick={handleNavigatePrevLesson}
          >
            <ArrowCircleLeftIcon />
            Previous
          </PreviousButton>
        )}
        {tutorial && <EmptyLessonTip animation={false} />}
        {lessonCompleted && (
          <LessonTip>Well done! Time to move on to the next lesson.</LessonTip>
        )}
        {(tutorial || lessonCompleted) && (
          <NextLessonButton
            shape="rounded"
            variant="outlined"
            onClick={handleNavigateNextLesson}
          >
            Next Lesson
            <ArrowCircleRightIcon />
          </NextLessonButton>
        )}
      </ButtonWrapper>
    </BottomBarStyling>
  );
};

BottomBar.propTypes = {
  lessonCompleted: PropTypes.bool,
  lessonNumber: PropTypes.number.isRequired,
  tutorial: PropTypes.bool,
};

BottomBar.defaultProps = {
  tutorial: false,
};

const BottomBarStyling = styled('div')`
  height: 100px;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-top: 1px solid grey;
`;

const ButtonWrapper = styled('div')`
  display: flex;
  flex-grow: 1;
  max-width: 95%;
  align-items: center;
  justify-content: center;
`;

const NextLessonButton = styled(Button)`
  width: ${({ theme }) => theme.spacing(20)};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LessonTip = styled(Box)`
  flex-grow: 1;
  max-width: 460px;
  margin: 0 40px;
  font-size: 1em;
  line-height: 1.3;
  text-align: center;
`;

const EmptyLessonTip = styled('div')`
  flex-grow: 1;
  max-width: 460px;
  height: 25px;
  margin: 0 20px;
`;

const TryAgainButton = styled(Button)({});

const PreviousButton = styled(Button)`
  width: ${({ theme }) => theme.spacing(17)};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BackToLessonsButton = styled(Button)`
  width: ${({ theme }) => theme.spacing(23)};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
