import * as React from 'react';
import LockIcon from '@mui/icons-material/Lock';
import { PropTypes } from 'prop-types';
import { Typography, Modal, Box, Button, Divider } from '@mui/material';

import { UserAuth } from 'Contexts/AuthContext';

import styled from 'styled-components';

export const LessonModal = ({ open, handleClose }) => {
  const { user } = UserAuth();

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <StyledBox>
          <FlexWrapper>
            <Typography variant="h5">This lesson is locked!</Typography>
            <LockIcon />
          </FlexWrapper>
          <Divider sx={{ mt: 2 }} />
          {!user.uid ? (
            <>
              <Typography sx={{ mt: 2 }}>
                Please sign in to unlock more lessons and track your progress{' '}
              </Typography>
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                href="/login"
              >
                Sign In
              </Button>
            </>
          ) : (
            <Typography sx={{ mt: 2 }}>
              Please complete the previous lesson to unlock it.
            </Typography>
          )}
        </StyledBox>
      </Modal>
    </div>
  );
};

LessonModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

const StyledBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  border: 2px solid #000;
  background-color: white;
  padding: 40px;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;
