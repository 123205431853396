import React from 'react';
import styled from 'styled-components';

const DoubleFretDotBottomStyle = styled.div`
  height: 20px;
  width: 20px;
  background-color: #fefbf8;
  border-radius: 50%;
  position: absolute;
  margin-left: 40px;
  margin-top: 153.5px;
  z-index: 10;
  box-shadow: inset 0 0 2px rgba(255, 255, 255, 0.5),
    0 2px 4px rgba(0, 0, 0, 0.3);
`;

export function DoubleFretDotBottom() {
  return (
    <div>
      <DoubleFretDotBottomStyle />
    </div>
  );
}
