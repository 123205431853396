import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import { Grid } from '@mui/material';

export function OptionsToolbar({
  onStringSelect,
  onNoteSelect,
  onOpenNoteToggle,
  fretCount,
  onFretSelect,
}) {
  const [note, setNote] = useState('E');
  const [string, setString] = useState('All');
  const [openString, setOpenString] = useState(true);

  useEffect(() => {
    onStringSelect(string);
    onNoteSelect(note);
  }, [note, onNoteSelect, onStringSelect, string]);

  const handleNoteChange = (event) => {
    setNote(event.target.value);
    onNoteSelect(event.target.value);
  };

  const handleStringChange = (event) => {
    setString(event.target.value);
    onStringSelect(event.target.value);
  };

  const handleOpenStringChange = (event) => {
    setOpenString(event.target.checked);
    onOpenNoteToggle(event.target.checked);
  };

  return (
    <div>
      <Grid
        container
        spacing={0}
        marginTop={5}
        marginBottom={3}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={3}>
          <FormControl>
            <FormControlLabel
              control={
                <Switch
                  checked={openString}
                  onChange={handleOpenStringChange}
                />
              }
              color="red"
              labelPlacement="top"
              label="Open Strings"
              style={{ color: '#666666' }}
            />
          </FormControl>
          <FormControl variant="filled" sx={{ m: 1, minWidth: 110 }}>
            <TextField
              id="standard-size-small"
              label="Fret Count"
              type="number"
              value={fretCount}
              onChange={onFretSelect}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: {
                  max: 14,
                  min: 1,
                },
              }}
            />
          </FormControl>
          <FormControl variant="filled" sx={{ m: 1, minWidth: 140 }}>
            <InputLabel id="string-label">Select String</InputLabel>
            <Select
              labelId="string-label"
              id="string-label-helper"
              value={string}
              label="Select Notes"
              onChange={handleStringChange}
            >
              <MenuItem value="All">
                <em>All</em>
              </MenuItem>
              <MenuItem value={0}>1st</MenuItem>
              <MenuItem value={1}>2nd</MenuItem>
              <MenuItem value={2}>3rd</MenuItem>
              <MenuItem value={3}>4th</MenuItem>
              <MenuItem value={4}>5th</MenuItem>
              <MenuItem value={5}>6th</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="filled" sx={{ m: 1, minWidth: 140 }}>
            <InputLabel id="note-label">Show Notes</InputLabel>
            <Select
              labelId="note-label"
              id="note-label-id"
              value={note}
              label="Select Notes"
              onChange={handleNoteChange}
            >
              <MenuItem value="None">
                <em>On hover</em>
              </MenuItem>
              <MenuItem value="All">
                <em>All</em>
              </MenuItem>
              <MenuItem value="A">A</MenuItem>
              <MenuItem value="B">B</MenuItem>
              <MenuItem value="C">C</MenuItem>
              <MenuItem value="D">D</MenuItem>
              <MenuItem value="E">E</MenuItem>
              <MenuItem value="F">F</MenuItem>
              <MenuItem value="G">G</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
}

OptionsToolbar.propTypes = {
  onStringSelect: PropTypes.func.isRequired,
  onNoteSelect: PropTypes.func.isRequired,
  onOpenNoteToggle: PropTypes.func.isRequired,
  fretCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  onFretSelect: PropTypes.func.isRequired,
};
