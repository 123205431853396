import React, { useEffect, useState } from 'react';
import { Container } from '@mui/system';
import {
  Typography,
  Paper,
  Box,
  Grid,
  TextField,
  Button,
  Link,
  Divider,
} from '@mui/material';
import { GoogleButton } from 'react-google-button';
import { UserAuth } from 'Contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { AuthNavbar } from './AuthNavbar';

export function SignIn() {
  const navigate = useNavigate();
  const { login, googleSignIn, user } = UserAuth();

  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  // const [error, setError] = useState('');

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(loginEmail, loginPassword);
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    if (user.displayName) {
      navigate('/lessons');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.displayName]);

  return (
    <>
      <AuthNavbar />
      <Container component="main" maxWidth="sm">
        <StyledPaper elevation={15}>
          <Typography component="h1" variant="h5">
            Sign In
          </Typography>
          <Grid
            container
            sx={{ mb: 3 }}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12}>
              <GoogleButton onClick={handleGoogleSignIn} />
            </Grid>
          </Grid>
          <Divider flexItem>OR</Divider>
          <Box component="form" noValidate sx={{ mt: 3 }}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={(event) => setLoginEmail(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={(event) => setLoginPassword(event.target.value)}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={(e) => handleSubmit(e)}
            >
              Sign In
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                Don&apos;t have an account?
                <Link href="/register"> Register here</Link>
              </Grid>
            </Grid>
          </Box>
        </StyledPaper>
      </Container>
    </>
  );
}

const StyledPaper = styled(Paper)`
  padding: 50px 50px;
`;
