import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { String } from './String';

const MiddleFretStyle = styled.div`
  background: linear-gradient(to bottom, #777 0%, #555 100%);
  border-right: 4px solid #ccc;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px 0px 10px 0px;
  width: 85px;
  height: 180px;
`;

export function MiddleFret({
  fretIndex,
  notes,
  selectedNote,
  selectedString,
  accidentals,
  naturals,
  hideHover,
}) {
  return (
    <div>
      <MiddleFretStyle>
        {selectedString === 'All'
          ? notes.map((note, index) => (
              <String
                key={note}
                stringIndex={index + 1}
                fretIndex={fretIndex}
                note={note}
                selectedNote={selectedNote}
                accidentals={accidentals}
                naturals={naturals}
                hideHover={hideHover}
              />
            ))
          : notes.map((note, index) => {
              if (index === Number(selectedString)) {
                return (
                  <String
                    key={note}
                    stringIndex={index + 1}
                    fretIndex={fretIndex}
                    note={note}
                    selectedNote={selectedNote}
                    accidentals={accidentals}
                    naturals={naturals}
                    hideHover={hideHover}
                  />
                );
              }
              return (
                <String
                  key={note}
                  stringIndex={index + 1}
                  fretIndex={fretIndex}
                  hideNote
                  note={note}
                  selectedNote={selectedNote}
                  accidentals={accidentals}
                  naturals={naturals}
                  hideHover={hideHover}
                />
              );
            })}
      </MiddleFretStyle>
    </div>
  );
}

MiddleFret.propTypes = {
  fretIndex: PropTypes.number.isRequired,
  notes: PropTypes.instanceOf(Array).isRequired,
  selectedNote: PropTypes.string.isRequired,
  selectedString: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  accidentals: PropTypes.bool.isRequired,
  naturals: PropTypes.bool.isRequired,
  hideHover: PropTypes.bool.isRequired,
};
