import React, { useState } from 'react';
import { Neck } from './Neck';
import { OptionsToolbar } from '../OptionsToolbar/OptionsToolbar';

export function Guitar() {
  const [fretCount, setFretCount] = useState(12);
  const [selectedNote, setSelectedNote] = useState('');
  const [selectedString, setSelectedString] = useState('');
  const [openString, setOpenString] = useState(true);

  const handleOnFretSelect = (event) => {
    setFretCount(Number(event.target.value));
  };

  const handleOnNoteSelect = (note) => {
    setSelectedNote(note);
  };

  const handleOnStringSelect = (string) => {
    setSelectedString(string);
  };

  const handleOpenStringSwitch = (openBoolean) => {
    setOpenString(openBoolean);
  };

  return (
    <div>
      <OptionsToolbar
        onFretSelect={handleOnFretSelect}
        onNoteSelect={handleOnNoteSelect}
        onStringSelect={handleOnStringSelect}
        onOpenNoteToggle={handleOpenStringSwitch}
        fretCount={fretCount}
      />
      <Neck
        fretCount={fretCount}
        selectedNote={selectedNote}
        selectedString={selectedString}
        showOpenString={openString}
      />
    </div>
  );
}
