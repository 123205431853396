/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import { GuitarContext } from 'Contexts/GuitarContext';
import styled from 'styled-components';
import useSound from 'use-sound';

export function OpenNote({ note, stringIndex, fretIndex, showOpenString }) {
  const { setNoteClicked, soundOff } = useContext(GuitarContext);

  const noteSound = require(`../../sounds/guitar/${note}.mp3`);

  const [play] = useSound(noteSound);
  const trueNote = note.slice(0, -1);

  const playNote = () => {
    setNoteClicked((prevState) => ({
      ...prevState,
      fretIndex,
      stringIndex,
      note: trueNote,
    }));
    if (!soundOff) {
      play();
    }
  };

  if (showOpenString) {
    return <NoteStyle onClick={playNote}>{trueNote}</NoteStyle>;
  } else {
    return <HiddenNote onClick={playNote}></HiddenNote>;
  }
}

OpenNote.propTypes = {
  stringIndex: PropTypes.number.isRequired,
  fretIndex: PropTypes.number.isRequired,
  note: PropTypes.string.isRequired,
  showOpenString: PropTypes.bool.isRequired,
  hideHover: PropTypes.bool.isRequired,
};

const NoteStyle = styled.div`
  height: 20px;
  width: 20px;
  margin-left: 3px;
  margin-top: -10px;
  text-align: center;
  background-color: #fefbf8;
  border-radius: 50%;
  position: absolute;
  z-index: 45;
  cursor: pointer;
  box-shadow: inset 0 0 2px rgba(255, 255, 255, 0.5),
    0 2px 4px rgba(0, 0, 0, 0.3);
`;

const HiddenNote = styled.div`
  height: 20px;
  width: 20px;
  margin-left: 3px;
  margin-top: -10px;
  text-align: center;
  position: absolute;
  z-index: 45;
  cursor: pointer;
`;
