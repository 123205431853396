import React, { useContext, createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
} from 'firebase/auth';
import { auth, db } from 'firebase-config.js';
import { doc, setDoc, getDoc, updateDoc } from 'firebase/firestore';

const AuthContext = createContext();

const provider = new GoogleAuthProvider();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({ uid: '', email: '', displayName: '' });
  const [userLoading, setUserLoading] = useState(true);

  const actionCodeSettings = {
    url: `${process.env.REACT_APP_FIREBASE_BASE_URL}/lessons`,
    handleCodeInApp: true,
  };

  const googleSignIn = async () => {
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      if (errorCode === 'auth/auth-domain-config-required') {
        alert('Domain Config Required');
        console.log('Sign in failed because: ', errorMessage);
      } else {
        alert(errorMessage);
        console.log('There was an error trying to log in: ', errorMessage);
      }
    }
  };

  const register = async (
    registerEmail,
    registerPassword,
    firstName,
    lastName
  ) => {
    try {
      await createUserWithEmailAndPassword(
        auth,
        registerEmail,
        registerPassword
      );

      await updateProfile(auth.currentUser, {
        displayName: firstName + ' ' + lastName,
      }).then(() => {
        setUser((prevUser) => {
          return { ...prevUser, displayName: firstName + ' ' + lastName };
        });
      });
      if (auth.currentUser.emailVerified === false) {
        await sendEmailVerification(auth.currentUser, actionCodeSettings);
      }
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      if (errorCode === 'auth/weak-password') {
        alert('The password is too weak.');
      } else {
        alert(errorMessage);
      }
      console.log('Unable to register properly due to:', error.message);
    }
  };

  const login = async (loginEmail, loginPassword) => {
    try {
      await signInWithEmailAndPassword(auth, loginEmail, loginPassword);
      // If user does not have a display name then create display name from email
      if (!auth.currentUser.displayName) {
        await updateProfile(auth.currentUser, {
          displayName: loginEmail,
        }).then(() => {
          setUser((prevUser) => {
            return { ...prevUser, displayName: loginEmail };
          });
        });
      }
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      if (errorCode === 'auth/wrong-password') {
        alert('Incorrect Password.');
      } else if (errorCode === 'auth/user-not-found') {
        alert('Email address not found.');
      } else {
        alert(errorMessage);
      }
      console.log('Unable to login properly due to: ', error.message);
    }
  };

  const logout = async () => {
    await signOut(auth);
  };

  /**
   * Create user document in the Users collection if user doesn't already exist.
   * User document created with user id as the document id.
   * @param {*} auth
   */
  const createUserDoc = async (auth) => {
    await setDoc(doc(db, 'Users', auth.currentUser.uid), {
      displayName: auth.currentUser.displayName ?? auth.currentUser.email,
      uid: auth.currentUser.uid,
      email: auth.currentUser.email,
      emailVerified: auth.currentUser.emailVerified,
      completedLessons: [],
    });
  };

  /**
   * Update the user document
   * Should be used to update fields such as displayName, email, emailVerified
   * @param {*} auth
   */
  const updateUserDoc = async (auth) => {
    const userRef = doc(db, 'Users', auth.currentUser.uid);
    await updateDoc(userRef, {
      displayName: auth.currentUser.displayName ?? auth.currentUser.email,
      email: auth.currentUser.email,
      emailVerified: auth.currentUser.emailVerified,
    });
  };

  // Check the auth state of user and track locally
  useEffect(() => {
    onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        // Check user exists and either create or udpate user document accordingly.
        const userRef = doc(db, 'Users', currentUser.uid);
        const docSnap = await getDoc(userRef);

        if (docSnap.exists()) {
          updateUserDoc(auth);
        } else {
          createUserDoc(auth);
        }
        setUser(currentUser);
        setUserLoading(false);
      } else {
        setUser({});
        setUserLoading(false);
      }
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{ register, login, googleSignIn, logout, user, userLoading }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(AuthContext);
};

AuthContextProvider.propTypes = {
  children: PropTypes.node,
};
